import {IonSelect, IonSelectOption} from "@ionic/react";
import {t} from "i18next";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedPark} from "../../redux/slices/membershipSlice";
import {TGText} from "../TGElements";

const ParkListDropdown = () => {
    const dispatch = useDispatch()
    const {selectedPark,allParks} = useSelector(state => state.membership)


    return (
        <div style={{
            display:"flex",
            alignItems:"start",
            justifyContent:"center",
            flexDirection:"column",
            marginTop:'20px',
        }}>

            <TGText
                fontSize={16}
                fontWeight={500}
                margin={'0 0 10px 0'}
            >
                {t("choose-park-point")}
            </TGText>

            <IonSelect onIonChange={(e)=>dispatch(setSelectedPark(e.target.value))}
                       className={`ParkListDropdown ${selectedPark !== "" && "active"}`}
                       value={selectedPark}>
                {allParks && allParks.map((park, key) => (
                    <IonSelectOption key={key} value={park.id}>{park.name}</IonSelectOption>
                ))}
            </IonSelect>
        </div>
    );
};

export default ParkListDropdown;
