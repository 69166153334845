import React from 'react';
import {IonButtons, IonHeader, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import {TGText} from "../index";
import TGIcon from "../TGElements/TGIcon";
import {useDispatch, useSelector} from "react-redux";
import TGImg from "../TGElements/TGImg";
import {MasterCard, Visa} from "../../assets/images";
import {api, getActiveSession, setDefaultCard} from "../../helpers";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {setChangeCreditCardModal, setChangePaymentMethodModal} from "../../redux/slices/modalSlice";
import {setMembershipPaymentMethod, setPaymentMethodModal} from "../../redux/slices/membershipSlice";

const ChangePaymentMethodModal = () => {
  const {creditCardModalStatus: isActive} = useSelector(state => state.modal.changePaymentMethodModal)
  const {t} = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {paymentMethods} = useSelector(state => state.user)
  const {paymentType,selectedPackage} = useSelector(state => state.membership)
  const findBankTransferMethod = selectedPackage?.paymentMethods?.find(method => method.id === 3)
  const findCreditCardMethod = selectedPackage?.paymentMethods?.find(method => method.id === 1)
  const activeMethod = paymentType === 3 ? "bank" : "card";
  let defaultCard = null
  let maxTwoCards = []

  if (paymentMethods) {
    defaultCard = paymentMethods.find(card => card?.default === 1);
    maxTwoCards = paymentMethods.slice(0, 2)
  }


  const changePaymentMethodHandler = async (cardId) => {

    if (!cardId) {
      dispatch(setMembershipPaymentMethod(3))
      dispatch(setChangeCreditCardModal(false))
      return
    }

    const defaultCardRes = await setDefaultCard(cardId)
    dispatch(setMembershipPaymentMethod(1))
    dispatch(setChangeCreditCardModal(false))
  }

  const clickAddPaymentMethod = () => {
    dispatch(setChangeCreditCardModal(false))
    dispatch(setChangePaymentMethodModal(false))
    dispatch(setPaymentMethodModal(false))
    history.push('/menu/payments/add-payment-method')
  }

  const closeModalHandler = () => {
    dispatch(setChangeCreditCardModal(false))
  }

  return (
    <IonModal
      onDidDismiss={closeModalHandler}
      isOpen={isActive}
      showBackdrop={true}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      style={{'--height': 'auto'}}
    >

      <IonHeader className="ion-no-border ion-padding-start ion-padding-end">
        <IonToolbar className="TGHeaderToolbar ion-padding-top ion-padding-bottom">
          <IonTitle className="ion-no-padding ion-text-capitalize">{t('select-payment-method')}</IonTitle>
          <IonButtons slot="end">
            <TGIcon name={"close-modal"} size={30} onClick={() => dispatch(setChangeCreditCardModal(false))}/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div className={'ion-padding-start ion-padding-end'}>

        {findBankTransferMethod && (
          <div className={`CardWrapper`} onClick={() => changePaymentMethodHandler()}>
            <div className={"CardDetail"}>
              <TGIcon name={"bank-transfer"} width={39} height={40}/>
              <TGText fontSize={16} fontWeight={500}>
                Banka Havalesi
              </TGText>
            </div>
            {
              activeMethod === "bank" ? (
                <TGIcon
                  name={"circle-green-tick"}
                  width={25}
                  height={25}
                  style={{marginLeft: "auto"}}
                />
              ) : (
                <div className={"empty-circle"}></div>
              )
            }
          </div>
        )}

        {findCreditCardMethod && maxTwoCards.map((card, index) => (
          <div
            key={index}
            className={`CardWrapper`}
            onClick={() => changePaymentMethodHandler(card.id)}
          >
            <div className={"CardDetail"}>
              <div className={"PaymentMethodsContainerStart"}>
                <TGImg width={41} src={card?.card.brand === "MasterCard" ? MasterCard : Visa}/>
              </div>
              <div className={"PaymentMethodsContainerMiddle"}>
                <TGText fontSize={16} fontWeight={500}>**** **** **** {card?.card?.last4}</TGText>
              </div>
            </div>
            {
              activeMethod === "card" && card.default === 1 ? (
                <TGIcon
                  name={"circle-green-tick"}
                  width={25}
                  height={25}
                  style={{marginLeft: "auto"}}
                />
              ) : (
                <div className={"empty-circle"}></div>
              )
            }
          </div>
        ))}

        {findCreditCardMethod && <div className={`CardWrapper`} onClick={clickAddPaymentMethod}>
          <div className={"CardDetail"}>
            <TGText fontSize={16} fontWeight={600} margin={'0 10px 0 0'}>{t('add-card')}</TGText>
          </div>
          <TGIcon
            name={"circle-dark-plus"}
            width={40}
            height={40}
            style={{marginLeft: "auto"}}
          />
        </div>}

      </div>

    </IonModal>
  );
};

export default ChangePaymentMethodModal;