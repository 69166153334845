//Component
import Logout from '../../Logout'
import { TGVersionLabel } from '../../TGElements'
import MenuHighlights from './MenuHighlights'
import MenuList from './MenuList'
import {isUserLoggedIn} from "../../../helpers";

const MenuContent = () => {
  return (
    <div className='MenuContent'>
      <MenuHighlights />
      <MenuList />
      {isUserLoggedIn() && <Logout />}
      <TGVersionLabel />
    </div>
  )
}

export default MenuContent