import {TGIcon, TGImg, TGText} from "../TGElements";
import {NoVehicleSearch} from "../../assets/images";
import {t} from "i18next";
import React, {useRef, useState} from "react";
import {
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
} from "@ionic/react";
import uniqid from "uniqid";
import DeleteMembershipModal from "./DeleteMembershipModal";
import {api, getUserSubscription} from "../../helpers";
import {useSelector} from "react-redux";
import MembershipSubscription from "./MembershipVehicle";

const MembershipVehicles = () => {
    const {selectedPark,userSubscriptions} = useSelector(state => state.membership)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [removedSubscriptionId, setRemovedSubscriptionId] = useState(null)
    const [availableUntil, setAvailableUntil] = useState(null)
    const openDeleteModal = (id,date,slideRef) => {
        setRemovedSubscriptionId(id)
        setIsDeleteModalOpen(true);
        setAvailableUntil(date)
        slideRef?.current.close()
    }

    const handleRemoveMembershipVehicle = () => {
        api.deleteMembership(selectedPark, removedSubscriptionId).then((r) => {
            setIsDeleteModalOpen(false)
            getUserSubscription(selectedPark)
        })
    }

    const menuList = [
        {
            id: uniqid(),
            text: "delete",
            iconDelete: "remove-vehicle",
            iconCancel: "cancel-subscription",
            onClick: openDeleteModal
        },
    ];

    return (
        <div className={"MembershipVehicles"}>
            {userSubscriptions && userSubscriptions.length ?
                userSubscriptions.map((subscription) => (
                    <CustomIonSlide key={subscription.id} subscription={subscription} menuList={menuList}/>
                )) :
                <div className={"NoItemContainer"}>
                    <TGImg src={NoVehicleSearch}/>
                    <p>{t("no-vehicles-added")}</p>
                    <p>{t("parking-benefit")}</p>
                </div>
            }

            <DeleteMembershipModal type={availableUntil?'subscription':'free'} date={availableUntil} isDeleteModalOpen={isDeleteModalOpen} setIsDeleteModalOpen={setIsDeleteModalOpen} deleteHandler={handleRemoveMembershipVehicle}/>
        </div>
    );
};

const CustomIonSlide = ({subscription, menuList}) => {
    const slideRef = useRef(null);

    return (
        <IonItemSliding key={"slide"+subscription.id} ref={slideRef} className={"SubscriptionSlideItem"}>
            <MembershipSubscription style={{backgroundColor: "white"}} slideRef={slideRef} subscription={subscription}/>
            {!subscription.terminatedAt && (
                <IonItemOptions className={"MembershipVehicleOptions"} side="end">
                    {menuList.map((item, index) =>
                        <IonItemOption
                            className={"MembershipVehicleOption"}
                            onClick={() => item.onClick(subscription.id,subscription.availableUntil,slideRef)}
                            key={subscription.id}
                            disabled={item.disabled}
                        >
                            <div className={"actionButtonSlide"}>

                                {subscription.type === "free" ?
                                    <TGIcon name={item.iconDelete} size={21}/>
                                    : <TGIcon name={item.iconCancel} size={21}/>
                                }

                                <TGText
                                    textAlign={"center"}
                                    color={"var(--tg-white)"}
                                    fontWeight={600}
                                    textTransform={"none"}
                                    fontSize={12}
                                >
                                    {t(subscription.type === "free" ? "delete" : "cancel")}
                                </TGText>
                            </div>
                        </IonItemOption>
                    )}
                    <div className={"vehicleItemBackground"}/>
                </IonItemOptions>
            )}
        </IonItemSliding>
    )
}


export default MembershipVehicles;
