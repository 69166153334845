//React
import { useEffect, useState } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../redux/slices/globalSlice";
//Components
import { TGPage, Session, FailActiveSessionModal } from "../components";
//Helpers
import {useHistory, useLocation} from "react-router";
import { getActiveSession } from "../helpers";
//Capacitor
import { App as CapacitorApp } from "@capacitor/app";
import CableNotConnectedModal from "../components/ActiveSession/CableNotConnectedModal";
import {useIonRouter, useIonViewDidEnter} from "@ionic/react";
import ChangePaymentMethodModal from "./ChangePaymentMethodModal";
import {
  setChangeCreditCardModal,
  setChangeInvoiceTypeModal,
  setChangePaymentMethodModal
} from "../redux/slices/modalSlice";

const ActiveSession = () => {
  const history = useIonRouter();
  const dispatch = useDispatch();
  const [isIntervalStart, setIntervalStart] = useState(false)
  const {activeSession} = useSelector(state => state.session)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCableModalOpen, setIsCabelModelOpen] = useState(false)
  const location = useLocation()
  const isCableConnected = history.routeInfo.routeOptions?.isCableConnected

  useEffect(() => {
    setIntervalStart(true)
    CapacitorApp.addListener('appStateChange', () => dispatch(setLoading(false)));
    if (isCableConnected !== undefined && !isModalOpen
    ) {
      setIsCabelModelOpen(!isCableConnected);
    }

    return () => CapacitorApp.removeAllListeners()

  }, [])


    useEffect(() => {
      if(!Object.keys(activeSession).length && isIntervalStart){
        setIsCabelModelOpen(false)
        dispatch(setChangePaymentMethodModal(false))
        dispatch(setChangeCreditCardModal(false))
        dispatch(setChangeInvoiceTypeModal(false))
        dispatch(setLoading(false))
        setIsModalOpen(true)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSession])

  const closeModal = () => {
    setIsModalOpen(false)
    setIntervalStart(false)
    history.push("/map")
  }

  useIonViewDidEnter(()=>{
    if (!activeSession?.id) {
      dispatch(setChangePaymentMethodModal(true))
    }
  },[])

  useEffect(() => {
    getActiveSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if(isIntervalStart){
      const interval = setInterval(() => {
        const isLoading = false
        getActiveSession(isLoading).then((res) => {
          if (res?.total_consumption){
            setIsCabelModelOpen(false)
          }
        });
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [isIntervalStart]);

  return (
    <TGPage toolbar={false} scrollY={false} isSafeArea={true}>
      <Session setIntervalStart={setIntervalStart}/>
      <CableNotConnectedModal isCableModalOpen={isCableModalOpen} setCableModalOpen={setIsCabelModelOpen}/>
      <FailActiveSessionModal isOpen={isModalOpen} closeModal={closeModal}/>
      <ChangePaymentMethodModal />
    </TGPage>
  );
};

export default ActiveSession;
