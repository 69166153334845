import { useDispatch } from 'react-redux';
import { TGButton, TGIcon } from '../../../../TGElements';
import { setSearchModal } from '../../../../../redux/slices/modalSlice';

const SearchButton = () => {
    const dispatch = useDispatch()
    const searchOpen = () => {
        dispatch(setSearchModal(true))
    }
    return (
        <TGButton
        width={40}
        height={40}
        borderRadius={"14px"}
        backgroundColor={"#595959"}
        expand="none"
        cssClass="TGMapButton"
        onButtonClick={() => searchOpen()}
        backgroundActiveColor={"#808080"}
        >
            <TGIcon name="search" width={17} height={18} />
        </TGButton>
    );
}

export default SearchButton;
