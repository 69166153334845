//Components
import { TGButton, TGIcon} from '../../../../TGElements';
//Helper functions
import { getLocation } from '../../../../../helpers';
import { useState } from "react";
import {IonSpinner} from "@ionic/react";

const LocateButton = ({map}) => {
    const [loading, setLoading] = useState(false);
    const handleClicked = async () => {
        setLoading(true)
        const coords = await getLocation();
        map.map.setCenter(coords)
        map.map.setZoom(15)
        setLoading(false)
    }

    return (
        <TGButton
            width={40}
            height={40}
            borderRadius={"14px"}
            backgroundColor={"#595959"}
            expand="none"
            cssClass="TGMapButton"
            onButtonClick={() => handleClicked()}
            backgroundActiveColor={"#808080"}
            additionalStyle={{
                display: "grid",
                placeItems: "center",
                "--color":"var(--tg-gray)",
            }}
        >
            {loading ? <IonSpinner name="crescent" /> : <TGIcon name={'locate'} width={17} height={16} /> }
        </TGButton>
    );
}

export default LocateButton;
