import {Capacitor} from "@capacitor/core";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {env, getStories, handleStoryViewed} from "../../../../helpers";
import {Stories} from "../../../StoriesComponent";
import {
    setCurrentStoryIndex,
    setIsShowStories,
    sortStoryPreviews
} from "../../../../redux/slices/storiesSlice";
import {FilterButtons} from "./Buttons";

const MapFilterButtons = () => {
    const isNative = Capacitor.isNativePlatform()
    const isIos = Capacitor.getPlatform() === "ios"
    const {viewed, storyPreviews} = useSelector(state => state.stories)
    const {mapLoading} = useSelector(state => state.global)
    const {searchModal} = useSelector(state => state.modal)
    const dispatch = useDispatch()
    const isHistoryEnabled = env("MAP_HISTORY_ENABLED") === "true"

    const buttons = [
        {name: "onlyAvailable", label: "available", width: 130, icon: {name: "default-charger", width: 20.7, height: 20.7}},
        {name: "dc", label: "DC", width: 90, icon: {name: "dc-charger", width: 14.9, height: 18.9}},
        {name: "ac", label: "AC", width: 90, icon: {name: "ac-charger", width: 16.7, height: 15}}

    ]


    useEffect(() => {
        isHistoryEnabled && getStories().then((stories) => dispatch(sortStoryPreviews()))
    }, []);

    if (!isHistoryEnabled) {
        return (
            <div
                className={`MapFilterButtons no-story ${isNative && isIos ? "ios" : ""} ${
                    !mapLoading.status ? "active" : ""
                }`}
            >
                {buttons.map(({name, label, width, icon}) => (
                    <FilterButtons key={name} name={name} label={label} width={width} icon={icon}/>
                ))}
            </div>
        )
    }


    return (
        <>
            <div id={'StoryHeader'} className={`MapFilterButtons ${isNative && isIos ? "ios" : ""} ${!mapLoading.status && !searchModal.status ? "active" : ""}`}>
                <div className={"StorySliderContainer"}>
                    <div className={"StorySlider"}>

                        {/*don't viewed stories left side */}
                        {storyPreviews?.length > 0 && storyPreviews?.map((storyPreview, index) => {
                            const isViewed = viewed.includes(storyPreview.items[0].id)
                            return (
                                <div key={index} className={`StorySliderItem`}>
                                    <div
                                        onClick={() => {
                                            dispatch(setCurrentStoryIndex(storyPreview.items[0].id))
                                            dispatch(setIsShowStories(true))
                                            handleStoryViewed(storyPreview.items[0].id)
                                        }}
                                        className={`StorySliderItemImageWrapper ${isViewed && 'viewed'}`}>
                                        <img
                                            width={"100%"}
                                            height={"100%"}
                                            src={storyPreview.photo}
                                            alt="story-preview"
                                        />
                                    </div>
                                    <div className={"StorySliderItemText"}> {storyPreview.name} </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <Stories />
        </>
    );
}

export default MapFilterButtons;
