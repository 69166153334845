import React, {useEffect, useState} from 'react';
import {TGIcon, TGText} from "../../TGElements";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setChangeInvoiceTypeModal} from "../../../redux/slices/modalSlice";
import {getActiveSession, getMyCompanies, transactionUpdate} from "../../../helpers";
import {IonAlert} from "@ionic/react";
import {TextLoading} from "../../Invoices";
import {setSelectedCompanyId} from "../../../redux/slices/sessionSlice";

const InvoiceTypeWrapper = ({companyId, border = false}) => {
  const {t} = useTranslation()
  const {activeSession} = useSelector(state => state.session)
  const dispatch = useDispatch()
  const [selectedCompany, setSelectedCompany] = useState(null)

  const openChangeInvoiceTypeModal = () => {
    if (companyId) return
    dispatch(setChangeInvoiceTypeModal(true))
  }

  useEffect(()=>{
    if(companyId){
      getMyCompanies(false).then(({data}) => {
        if (data.length > 0) {
          const tmp =  data.filter(c => c.company_id === companyId)
          if (tmp) setSelectedCompany(...tmp)
        }
      })
    }
  },[companyId])

  const removeCompanyHandler = () => {
    dispatch(setSelectedCompanyId(null))
    transactionUpdate(activeSession.id, null).then(() => {
      dispatch(setChangeInvoiceTypeModal(false))
      getActiveSession()
    })
  }


  return (
    <div
      className={`AddingInvoiceTypeWrapper ${border && "bordered"}`}
      onClick={openChangeInvoiceTypeModal}
    >
      <div className={"InvoiceSvgIconWrapper"}>
        <TGIcon name={companyId ? "fill-invoice" : "outline-invoice"} size={30}/>
      </div>
      <div className={"CompanyNameWrapper"}>
        <TextLoading fontSize={14} fontWeight={600} textTransform={"none"} width={"170px"} wrapWithDots={true}>
          {companyId ? selectedCompany?.company.company_name : t('adding-invoice-address')}
        </TextLoading>
        <TextLoading fontSize={10} fontWeight={500} margin={"2px 0 0 0 "} textTransform={"none"}>
          {companyId ? selectedCompany?.company.company_code : t('invoice-corporation-text')}
        </TextLoading>
      </div>
      {companyId && (
        <div id={"remove-company"} className={"remove-company"}>
          <TGIcon name={"left-side-delete"} width={18} height={15}/>
          <TGText fontSize={12} fontWeight={600} textTransform={"none"}>{t('remove')}</TGText>
        </div>
      )}

      {companyId && (
        <IonAlert
          trigger={"remove-company"}
          header={t('alert')}
          subHeader={t("remove-company-subheader")}
          buttons={[
            {
              text: t('no'),
              role: 'cancel',
            },
            {
              text: t('yes'),
              role: 'confirm',
              handler: () => removeCompanyHandler()
            }
          ]}
        ></IonAlert>
      )}
    </div>
  );
};

export default InvoiceTypeWrapper;