//components
import {TGButton, TGText} from "../../TGElements";
//redux
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Share} from "@capacitor/share";
import {setStationDetailModalOpen} from "../../../redux/slices/modalSlice";
import {env} from "../../../helpers";

const Header = () => {
    const {currentStation} = useSelector(state => state.station)
    const {stationDetailModal} = useSelector(state => state.modal)
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onClickHandler = async () => {
        const url = env('REDIRECT_URL') + "station?id=" + currentStation.id;
        const text = currentStation.name + "\n" + currentStation.address;
        try {
            await Share.share({ text, url});
            dispatch(setStationDetailModalOpen(false));
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className={"HeaderWrapper"}>
            <div>
                <TGText fontSize={18} fontWeight={"semibold"}>
                    {currentStation?.name ?? null}
                </TGText>
                {stationDetailModal.currentBreakpoint < 1 &&
                    <TGText fontSize={14} fontWeight={"regular"} margin={"5px 0 0 0"}
                            color={"var(--tg-secondary-text)"}>
                        {currentStation.address}
                    </TGText>
                }
            </div>
            <TGButton
                onButtonClick={onClickHandler}
                backgroundColor={"var(--tg-black)"}
                backgroundActiveColor={"var(--tg-black-active)"}
                cssClass={"ShareButton"}
            > {t('share')} </TGButton>
        </div>
    );
};

export default Header;
