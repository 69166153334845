import React from "react";
import {IonItem} from "@ionic/react";
import {t} from "i18next";
import {format} from "date-fns";

const MembershipSubscription = ({slideRef, subscription, selectedVehicle, setSelectedVehicle = null, style = {}}) => {

    const onClickHandler = (subscription) => {
        setSelectedVehicle && setSelectedVehicle(subscription)
    }

    const moreButtonHandler = (e) => {
        slideRef.current.open() || slideRef.current.close()
    }

    const dateTextFormat = (firstDAte, lastDate) => {
        if (firstDAte && lastDate) {
            return `${format(new Date(firstDAte), 'dd.MM.yyyy')} - ${format(new Date(lastDate), 'dd.MM.yyyy')}`
        }
        return ""
    }

    return (
        <IonItem lines={"none"} style={{...style, "--padding-start": "0px", "--padding-end": "0px","--inner-padding-end":"0px"}}
                 className={`SubscriptionListItem ${subscription.id === selectedVehicle?.id && "selected"}`}>
            <div onClick={() => onClickHandler(subscription)} key={subscription.id}
                 style={{
                     display: "flex",
                     width: "100%",
                     height: "100%",
                     padding: "0 16px",
                     position: "relative",
                     alignItems:"center",
                     mixBlendMode: subscription.terminatedAt && "luminosity",
            }}>
                <div className={"vehiclePlateWrapper"}>
                    <div className={`Badge subscription-${subscription.statusId}`}>{subscription.status}</div>
                    <p className={`vehiclePlate`}>{subscription.plate}</p>
                    {subscription.remainingDays && <p className={`RemainingDays`}>
                        {t('remaining-days')} <span>{subscription.remainingDays}</span>
                    </p>}
                </div>

                {!subscription.terminatedAt && (
                    <>
                        <div className={'divider'}></div>

                        <div className={"SubscriptionInfo"}>
                            <p className={"SubscriptionDate"}>{dateTextFormat(subscription.subscribedAt,subscription.availableUntil)}</p>
                            <p className={"SubscriptionVehicleType"}>{t('vehicle-type')} <span>{subscription.vehicleBodyType}</span></p>
                        </div>
                    </>
                )}

                <div className={"vehicleInfo"}>
                    <div className={"moreButton"} onClick={moreButtonHandler}>...</div>
                    {subscription.terminatedAt && (
                        <p dangerouslySetInnerHTML={{
                            __html: t('removed-subscription', {
                                date: format(new Date(subscription.availableUntil), 'dd.MM.yyyy - hh:mm')
                            })
                        }}></p>
                    )}
                </div>

            </div>
        </IonItem>

    );
};

export default MembershipSubscription;
