import React, { useEffect, useState } from "react";
import {TGButton, TGImg, TGPage, TGText, TGTextarea} from "../components";
import { useHistory, useParams } from "react-router";
import { t } from "i18next";
import {closeTicket, formatDate, getTicketDetails, getTickets, sendTicketAnswer} from "../helpers";
import TicketItem from "../components/ContactUs/TicketItem";
import {IonIcon} from "@ionic/react";
import {document, documentAttachOutline, image} from "ionicons/icons";
import Compressor from "compressorjs";
import {Alert} from "../helpers/alert";

const TicketDetail = () => {
  const history = useHistory();
  const params = useParams();
  const [ticketDetail, setTicketDetail] = useState(null)
  const [answer, setAnswer] = useState("")
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (params.id) {
      getTicketDetails(params.id)
        .then(res => res ? setTicketDetail(res) : history.goBack());
    }
  }, [params.id]);

  const sendAnswer = async () => {
    sendTicketAnswer(params.id, answer, file).then(res => {
      res.success && getTicketDetails(params.id).then(res => setTicketDetail(res));
      setAnswer("")
      setFile(null)
    })
  }

  const handleCompressedUpload = (e) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', "application/pdf"];
    const image = e.target.files[0];
    const fileOrBlob = image instanceof File || image instanceof Blob
    if(allowedTypes.includes(image.type) && fileOrBlob){
      if (image.type === 'application/pdf') {
        setFile(image)
        return
      }
      new Compressor(image, {
        quality: 0.8,
        success: (compressedResult) => {
          const image = new File([compressedResult], compressedResult.name);
          setFile(image)
        },
      });
    }
    else {
      Alert.error(t('img-type-is-not-allowed'))
    }
  };

  return (
    ticketDetail && (
      <TGPage
        title={t("contact-us")}
        scrollY={true}
        backButton={true}
        ionPadding={false}
        cssClass={"ion-padding-start ion-padding-end TicketDetail"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TGButton
            backgroundColor={"var(--tg-error)"}
            backgroundActiveColor={"var(--tg-error)"}
            width={123}
            height={35}
            margin={"1rem 0"}
            onButtonClick={() =>
              closeTicket(params.id).then(
                (res) => res.success && history.goBack()
              )
            }
          >
            {t("close")}
          </TGButton>
        </div>
        <TicketItem ticket={ticketDetail} isDetail={true}/>
        <TGTextarea rows={5} setValue={(e) => setAnswer(e.detail.value)}/>

        <label className="fileAttachmentWrapper" htmlFor="file-upload">
          <input id="file-upload" type="file" accept="image" onChange={handleCompressedUpload}/>
          <div className={'fileDescWrapper'}>
            {file &&
              <IonIcon icon={file.type === 'application/pdf' ? document : image} size={'small'} id={'text-icon'}/>}
            <TGText color={"var(--tg-secondary-text)"} wrapWithDots={true} width={'100%'}>
              {file?.name ?? t("file-attachment")}
            </TGText>
          </div>
          <IonIcon icon={documentAttachOutline} slot={'icon-only'} id={'icon'}/>
        </label>

        <TGButton margin={"1rem 0"} onButtonClick={() => sendAnswer()}>
          {t("send")}
        </TGButton>
        {ticketDetail.contents.map((thread) => (
          <div
            key={thread.id}
            className={`TicketThread ${
              thread.user_id ? "customer" : "supervisor"
            }`}
          >
            <div className="ThreadTop">
              <TGText
                textTransform={"capitalize"}
                color="var(--tg-primary-text)"
                textDecoration={"underline"}
              >
                {thread.user?.display_name ?? thread.supervisor?.name}
              </TGText>
              <TGText
                textTransform={"capitalize"}
                color="var(--tg-primary-text)"
              >
                {formatDate(thread.created_at)}
              </TGText>
            </div>
            <TGText
              textTransform={"capitalize"}
              color="var(--tg-primary-text)"
              dangerouslySetInnerHTML={{ __html: thread.message }}
            />
            {thread.file_type === 'application/pdf' ? (
              <a className={"downloadFile"} href={thread.file_url}>
                <IonIcon icon={document} size={'small'}/>
                {thread.file_url.split("/").pop()}
              </a>
            ) : (
              <img src={thread.file_url} className={"imageAttachment"}/>
            )}
          </div>
        ))}
      </TGPage>
    )
  );
};

export default TicketDetail;
