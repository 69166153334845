import {t} from "i18next";
import {getUserSubscription} from "../../helpers";
import MembershipVehiclesTab from "./MembershipVehiclesTab";
import {useSelector} from "react-redux";
import {useEffect} from "react";

const ParkDetailContainer = () => {
    const {selectedPark} = useSelector(state => state.membership)
    useEffect(() => {
        if (selectedPark) {
            getUserSubscription(selectedPark)
        }
    }, [selectedPark])


    return (selectedPark &&
        <div className={"ParkDetailContainer"}>
            <div className={"ParkDetailTabsContainer"}>
                <p className={`active`}>
                    {t("my-subscriptions")}
                </p>
            </div>

            <MembershipVehiclesTab />
        </div>

    );
};

export default ParkDetailContainer;
