import {TGText} from "../TGElements";
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedPackage,setMembershipPaymentMethod} from "../../redux/slices/membershipSlice";
import {env} from "../../helpers";
import React from "react";

const SubscriptionItem = ({item}) => {
    const {selectedPackage, paymentType} = useSelector(state => state.membership)
    const dispatch = useDispatch()

    const onClickHandler = () => {
        dispatch(setSelectedPackage(item))
        dispatch(setMembershipPaymentMethod(item.paymentMethods[0].id))
    }

    return (
        <>
            <div className={`SubscriptionItem ${selectedPackage?.id === item.id && "selected"}`}
                 onClick={onClickHandler}>
                <div>
                    <div>
                        <p className={"SubscriptionInfo"}>{item?.name}</p>
                        <div className={"Badge subscription"}> {t("subscription")}</div>
                    </div>

                    <TGText className={"SubscriptionPrice"} fontSize={24} fontWeight={600}>
                        {item?.cost + env("CURRENCY_SYMBOL")}
                    </TGText>
                </div>

                <div className={"SubscriptionVehicleInfo"}>
                    <TGText fontSize={12} fontWeight={500}>
                        Araç Tipi : <span className={'VehicleBodyType'}>{item?.vehicleBodyType.name}</span>
                    </TGText>
                </div>

            </div>

        </>
    );
};

export default SubscriptionItem;
