//React & Ionic
import {useState} from 'react';
import {IonIcon, IonInput, IonItem} from '@ionic/react';
//Components
import TGText from './TGText';
import {SearchInputIcons} from '../Search';
//Redux
import {useSelector} from 'react-redux';
import {TGErrorText, TGIcon} from "./index";

const TGInput = ({
                   type,
                   label,
                   value,
                   name,
                   autofocus,
                   disabled,
                   required,
                   textAlign,
                   placeholder,
                   onInputChange,
                   onInputFocus,
                   onInputBlur,
                   borderRadius,
                   margin,
                   error,
                   errorText,
                   reference,
                   inputmode,
                   pattern,
                   maxlength,
                   additionalStyles,
                   register,
                   lastIcon
                 }) => {

  const [focus, setFocus] = useState(false)
  const [inputText, setInputText] = useState("")
  const {searchLoading} = useSelector(state => state.search)

  const WRAPPER_STYLE = {
    width: "100%",
    flex: type === "search-modal" ? 1 : "unset",
    margin: margin ? margin : "0px",
    ...additionalStyles
  }

  return (
    <div style={WRAPPER_STYLE}>
      <IonItem lines='none' className={`${type === "search-modal" ? "TGSearchModalInputWrapper" : "TGInputWrapper"}`}>
        <div className={`TGInputBorder ${focus || (inputText || value) ? "focus" : ""} ${error ? "error" : ""}`}
             style={{
               borderRadius: borderRadius || 14
             }}
        >

          <IonInput
            id="TGInput"
            name={name}
            className={`TGInput ${focus || (inputText || value) ? "focus" : ""}`}
            type={type === "search" || type === "search-modal" ? "text" : type}
            disabled={disabled}
            required={required}
            autofocus={autofocus}
            placeholder={placeholder}
            onIonInput={e => {
              onInputChange && onInputChange(e)
              setInputText(e.detail.value)
            }}
            onIonFocus={(evt) => {
              onInputFocus && onInputFocus(evt)
              setFocus(true)
            }}
            onIonBlur={(e) => {
              onInputBlur && onInputBlur(e)
              setFocus(false)
            }}
            style={{textAlign: textAlign ? textAlign : "left"}}
            value={value !== undefined ? value : inputText}
            ref={reference}
            inputmode={inputmode}
            pattern={pattern}
            maxlength={maxlength}
            {...register}
          />
          {label && <label
            className={`TGInputLabel ${placeholder || focus || inputText ? "focus" : ""} ${error ? "--error" : ""}`}>{label}</label>}
          {lastIcon}
        </div>
      </IonItem>
      {(error && errorText) && <TGErrorText text={errorText}/>}
    </div>
  )
}

export default TGInput;
