import {createSlice} from '@reduxjs/toolkit';

export const membershipSlice = createSlice({
    name: 'membership',
    initialState: {
        selectedPark: "",
        allParks: [],
        userVehicles: [],
        membershipDetail: {
            availableQuota: 0,
            freeQuota: 0,
            subscriptionCount: 0,
            vehicleCount: 0,
        },
        allPackages: [],
        selectedPackage: null,
        membershipPlate: null,
        autoRenew: false,
        paymentType: null, // 1: credit card, 3: bank transfer
        userSubscriptions: [],
        paymentMethodModal: false
    },
    reducers: {
        setUserVehicles: (state, action) => {
            state.userVehicles = action.payload;
        },
        setMembershipDetail: (state, action) => {
            state.membershipDetail = action.payload;
        },
        setSelectedPark: (state, action) => {
            state.selectedPark = action.payload;
        },
        setAllParks: (state, action) => {
            state.allParks = action.payload;
        },
        setAllPackages: (state, action) => {
            state.allPackages = action.payload;
        },
        setSelectedPackage: (state, action) => {
            state.selectedPackage = action.payload;
        },
        setMembershipPlate: (state, action) => {
            state.membershipPlate = action.payload;
        },
        setAutoRenew: (state, action) => {
            state.autoRenew = action.payload;
        },
        setMembershipPaymentMethod: (state, action) => {
            state.paymentType = action.payload;
        },
        setUserSubscriptions: (state, action) => {
            state.userSubscriptions = action.payload;
        },
        setPaymentMethodModal: (state, action) => {
            state.paymentMethodModal = action.payload;
        }
    },
});

export const {
    setUserVehicles,
    setMembershipDetail,
    setSelectedPark,
    setAllParks,
    setAllPackages,
    setSelectedPackage,
    setMembershipPlate,
    setAutoRenew,
    setMembershipPaymentMethod,
    setUserSubscriptions,
    setPaymentMethodModal
} = membershipSlice.actions
export default membershipSlice.reducer;