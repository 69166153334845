import {TGIcon} from "../TGElements";
import React, {useState} from "react";
import MembershipVehicles from "./MembershipVehicles";
import MembershipAddVehicleModal from "./MembershipAddVehicleModal";
import {api, getUserSubscription, plateValidation} from "../../helpers";
import {useSelector} from "react-redux";
import {Alert} from "../../helpers/alert";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const MembershipVehiclesTab = () => {
    const {selectedPark, membershipDetail} = useSelector(state => state.membership)
    const history = useHistory()
    const {t} = useTranslation()
    const [isAddVehicleModalOpen, setIsAddVehicleModalOpen] = useState(false);
    const [plate, setPlate] = useState(null)
    const {company: {tel, tel_formatted}} = useSelector(state => state.config)

    const addMembershipVehicle = () => {

        const validPlate = plateValidation(plate)
        if (!validPlate) return Alert.error(t('valid-plate'))

        api.addMembershipVehicle(selectedPark, plate).then((r) => {
            if (r.data.success) {
                getUserSubscription(selectedPark)
                setIsAddVehicleModalOpen(false)
                setPlate(null)
            }
        })
    }

    const clickHandler = () => {
        if (membershipDetail.availableQuota > 0) {
            setIsAddVehicleModalOpen(true)
        } else {
            history.push("/menu/membership/add-subscription")
        }
    }

    return (
        <>
            <MembershipVehicles/>
            <div className={"addVehicleTabButtonContainer ion-padding-start ion-padding-end"}>
                <div onClick={clickHandler} className={"addVehicleTabButton"}>
                    {t("membership-request")}
                </div>
            </div>

            <MembershipAddVehicleModal buttonHandler={addMembershipVehicle} plate={plate} setPlate={setPlate}
                                       isAddVehicleModalOpen={isAddVehicleModalOpen}
                                       setIsAddVehicleModalOpen={setIsAddVehicleModalOpen}/>
        </>
    );
};


export default MembershipVehiclesTab;
