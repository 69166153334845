import {TGButton, TGIcon, TGText} from '../../../../TGElements';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {env} from "../../../../../helpers";

const CampaignButton = () => {
    const {t} = useTranslation()
    const history = useHistory()
    const {activeSession} = useSelector(state => state.session);
    const isActive = Object.keys(activeSession).length;
    const isMembershipActive = env("IS_MEMBERSHIP_ACTIVE") === "true";

    const handleButtonClick = () => {
        if (isMembershipActive) {
            return  history.push("/menu/membership/list")
        }
        history.push("/campaign")
    }

    return (
        <div className={`TGMapButtonWrapper ${isActive && 'activeSession'}`}>
            <TGButton
                width={48}
                height={48}
                borderRadius={"18px"}
                backgroundColor={isMembershipActive ? "var(--tg-park)" : "var(--tg-white)"}
                expand="none"
                cssClass="TGMapButton"
                onButtonClick={handleButtonClick}
                backgroundActiveColor={isMembershipActive ? "var(--tg-park)" : "var(--tg-secondary-text)"}
                additionalStyle={{
                    color: 'var(--tg-secondary)',
                }}
            >
                <TGIcon name={isMembershipActive ? "parking" : "gift-box"} width={24} height={24}/>
            </TGButton>
            <TGText fontSize={14} fontWeight={500}>
                {isMembershipActive ? t('subscription') : t('gift-text')}
            </TGText>
        </div>
    );
}

export default CampaignButton;
