import {IonContent, IonItem, IonPopover} from "@ionic/react";
import {TGIcon, TGImg, TGText} from "../../TGElements";
import {setDefaultVehicle} from "../../../helpers";
import React from "react";
import {useTranslation} from "react-i18next";

const Vehicle = ({vehicle, slideRef}) => {
    const {t} = useTranslation()
    const handleSetDefault = async () => {
        await setDefaultVehicle(vehicle.id)
    }


    const moreButtonHandler = () => {
        const isOpen = slideRef.current.state === 2
        isOpen ? slideRef.current.open() : slideRef.current.close()
    }

    return (
        <IonItem className={`MyVehicle MyVehicleItem ${vehicle.is_default ? "DefaultVehicle" : ""}`}>
            <div>
                <TGImg
                    src={vehicle.image}
                    width={130}
                    height={'auto'}
                    reflect={true}
                    cssClass="VehicleImage"
                />
                <div>
                    <div>
                        {vehicle.model_text ?
                            <>
                                <TGImg src={vehicle.logo} size={40} cssClass={'brandLogo'}/>
                                <TGText
                                    fontSize={14}
                                    fontWeight={"medium"}
                                    width={130}
                                    wrapWithDots={true}
                                >
                                    {vehicle.model_text}
                                </TGText>
                            </>
                            :
                            <div
                                className={"addVehicle"}
                                onClick={() => slideRef?.current.open()}
                            > {t('enter-brand')} </div>
                        }
                    </div>
                    <div
                        style={{
                            border: "1px solid var(--tg-inactive)",
                            width: 150,
                            padding: "4px 0",
                            borderRadius: 8,
                            marginTop: 8,
                        }}
                    >
                        <TGText
                            margin={"0 auto"}
                            textTransform={"uppercase"}
                            fontSize={20}
                            fontWeight={"bold"}
                        >
                            {vehicle.plate}
                        </TGText>
                    </div>
                </div>

                <div className={'vehicleDefaultButton'}>
                    <div
                        className={"moreButton"}
                        onClick={moreButtonHandler}
                    >...</div>
                    <TGIcon
                        onClick={handleSetDefault}
                        name={vehicle.is_default ? 'star-fill-green' : 'star-outline'}
                        width={16}
                        height={17}
                    />
                </div>
            </div>
        </IonItem>
    );
};

export default Vehicle;
