import React from 'react';
import {TGIcon, TGPageHeader} from "../TGElements";
import {IonModal} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import tr from "date-fns/locale/tr";

const DeleteMembershipModal = ({isDeleteModalOpen, setIsDeleteModalOpen, deleteHandler, type, date}) => {
    const {t} = useTranslation();

    const list = {
        "free": {
            icon: "membership-remove",
            btnText: "delete",
            title: "remove-park-membership",
            text: "remove-park-are-you-sure",
            onClick: () => deleteHandler()
        },
        "subscription": {
            icon: "membership-cancel",
            btnText: "cancel",
            title: "cancel-park-membership",
            text: "cancel-subscription-are-you-sure",
            onClick: () => deleteHandler()
        }
    }

    return (
        <IonModal
            className={"TGModal"}
            isOpen={isDeleteModalOpen}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
            onDidDismiss={() => setIsDeleteModalOpen(false)}
            style={{'--height': 'auto'}}
        >
            <div>
                <TGPageHeader backButton={false}/>
                <div className={"deleteMembershipModal"}>
                    <TGIcon name={list[type].icon} width={90} height={170}/>
                    <p>{t(list[type].title)}</p>
                    <p dangerouslySetInnerHTML={{__html:t(list[type].text,{date:format(new Date(date??'0'),'dd MMMM yyy',{locale:tr})})}}></p>
                    <button onClick={list[type].onClick} className={"deleteButton"}>{t(list[type].btnText)}</button>
                </div>
            </div>
        </IonModal>
    );
};

export default DeleteMembershipModal;