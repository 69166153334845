import {TGButton, TGImg, TGText} from "../TGElements";
import {NoParkSearch} from "../../assets/images";
import {t} from "i18next";
import SubscriptionItem from "./SubscriptionItem";
import {useDispatch, useSelector} from "react-redux";
import {api, env, getMyCompanies, plateValidation} from "../../helpers";
import {setAllPackages, setPaymentMethodModal} from "../../redux/slices/membershipSlice";
import {Alert} from "../../helpers/alert";
import {useIonViewDidEnter} from "@ionic/react";
import {InvoiceTypeWrapper, SelectInvoiceTypeModal} from "../ChangePaymentMethodModal";
import React, {useState} from "react";

const Subscriptions = () => {
    const {
        selectedPark,
        allPackages,
        selectedPackage,
        membershipPlate,
    } = useSelector(state => state.membership)
    const {selectedCompanyId} = useSelector(state => state.session)
    const dispatch = useDispatch()

    const getAllSubscriptions = () => {
        api.getMembershipAllPackages(selectedPark).then((res) => {
            if (res.data.success) {
                dispatch(setAllPackages(res.data.data))
            }
        })
    }

    useIonViewDidEnter(() => {
        if (selectedPark) {
            getAllSubscriptions()
        }
    }, [selectedPark]);

    const startSubscription = () => {
        if (!membershipPlate || !plateValidation(membershipPlate)) {
            Alert.error(t("valid-plate"))
            return
        }

        if (!selectedPackage) {
            Alert.error(t("select-package"))
            return
        }

        dispatch(setPaymentMethodModal(true))
    }


    return (
        <div className={"ParkDetailTabSubscriptions"}>
            {allPackages && allPackages.length ?
                allPackages.map((item) => (
                    <SubscriptionItem key={item.id} item={item}/>
                )) :
                <div className={"NoItemContainer"}>
                    <TGImg margin={20} src={NoParkSearch}/>
                    <p>{t("no-packages")}</p>
                </div>
            }

            {allPackages && allPackages.length > 0 && (
                <>
                    <TGText margin={"24px 0 16px 0"} fontWeight={600} textTransform={"none"}>
                        {t('invoice-address')}
                    </TGText>
                    <InvoiceTypeWrapper companyId={selectedCompanyId?? null} border={true}/>
                </>
            )}


            <TGButton
                onButtonClick={startSubscription}
                additionalStyle={{
                    position: "absolute",
                    bottom: '5%',
                    width: 'calc(100% - 32px)'
                }}
            >{t("membership-request")}</TGButton>

            <SelectInvoiceTypeModal/>
        </div>
    );
};

export default Subscriptions;
