import {TGIcon, TGText} from '../TGElements'
import {useSelector} from 'react-redux'
import {ElapsedTimer} from "../TimeCounters";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getActiveSession, isUserLoggedIn} from "../../helpers";

const ActiveSessionButton = () => {
    const {t} = useTranslation()
    const { searchModal } = useSelector((state) => state.modal);
    const {mapLoading} = useSelector(state => state.global)
    const {activeSession} = useSelector(state => state.session)
    const isActive = Object.keys(activeSession).length && !mapLoading.status && !searchModal.status;
    const [elapsedTime, setElapsedTime] = useState(activeSession?.duration ?? 0);
    const [isIntervalStart, setIntervalStart] = useState(false)

    useEffect(() => {
        if (isIntervalStart && isUserLoggedIn()) {
            const interval = setInterval(() => {
                getActiveSession(false);
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [isIntervalStart]);


    useEffect(() => {
        setIntervalStart(true)
        setElapsedTime(
            activeSession?.duration ?? 0
        );
    }, [activeSession]);


    return (
        <div className={`ActiveSessionButton ${isActive ? "active" : ""}`}>
            <div className="consumption">
                <TGText fontSize={24} width={"auto"} textAlign={"left"} fontWeight={"bold"} textTransform={"none"}>
                    {activeSession.total_consumption ?? '0'} kw
                </TGText>

                <div className={"charge-speed"}>
                    <TGIcon name="charge-speed-noBg" size={16} cssClass={"charge-speed"}/>
                    <TGText fontSize={12} fontWeight={"medium"} textTransform={"unset"}>
                        {activeSession?.kwh ?? '0'} {t('charge-speed-kw')}
                    </TGText>
                </div>
            </div>

            <ElapsedTimer
                seconds={elapsedTime}
                setSeconds={setElapsedTime}
                isActive={true}
                classname={"ElapsedTimeText"}
            />
        </div>
    )
}

export default ActiveSessionButton