import { createSlice } from "@reduxjs/toolkit"

export const globalSlice = createSlice({
  name: "globalSlice",
  initialState: {
    loading: {
        status: false,
        message: "loading"
    },
    mapLoading: {
      status: false,
      message: "map-loading"
    },
    alert: {
      status: false,
      message: "",
      type: null,
    },
    route:{
      previousPath: "",
      currentPath: ""
    },
    qrRedirection: {},
    mailVerificationToast: false
  },
  reducers: {
    setLoading: (state, action) => {
        if(typeof action.payload === "boolean"){
            state.loading.status = action.payload;
        }else{
            state.loading = action.payload;
        }
    },
    setMapLoading: (state, action) => {
      if(typeof action.payload === "boolean"){
        state.mapLoading.status = action.payload;
      }else{
          state.mapLoading = action.payload;
      }
    },
    setAlert: (state, action) => {
      state.alert = action.payload
    },
    setRoute: (state, action) => {
      state.route = action.payload
    },
    setQrRedirection: (state, action) => {
      state.qrRedirection = action.payload
    },
    resetQrRedirectionState: (state) => {
      state.qrRedirection = {}
    },
    setMailVerificationToast: (state, action) => {
        if (!state.mapLoading.status) {
            state.mailVerificationToast = action.payload
        }
    }
  },
})

export const {
    setLoading,
    setAlert,
    setRoute,
    setMapLoading,
    setQrRedirection,
    resetQrRedirectionState,
    setMailVerificationToast
} = globalSlice.actions
export default globalSlice.reducer
