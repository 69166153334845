import React, {useEffect} from 'react';
import {
  IonToast,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillLeave
} from "@ionic/react";
import WarningIcon from "./warning_icon.svg";
import CloseIcon from "./close_icon.svg";
import "./MailVerificationToast.css";
import {useDispatch, useSelector} from "react-redux";
import {setMailVerificationToast} from "../../redux/slices/globalSlice";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const MailVerificationToast = () => {
  const {t} = useTranslation()
  const {user} = useSelector(state => state.user)
  const dispatch = useDispatch()
  const history = useHistory()
  const router = useIonRouter()
  const {mailVerificationToast} = useSelector(state => state.global)
  const {searchModal} = useSelector(state => state.modal)
  const isMailEntered = user?.info.email

  const closeToast = () => {
    dispatch(setMailVerificationToast(false))
  }

  const openToast = () => {
    if (history?.location.pathname === '/map' && user?.token && !user?.info.is_email_verified) {
      dispatch(setMailVerificationToast(true))
    }
  }

  useEffect(() => {
    if (user?.info.is_email_verified) {
      return closeToast()
    }
    openToast()
  }, [user])

  const goToProfile = () => {
    dispatch(setMailVerificationToast(false))
    history.push('/menu/edit-user')
  }

  // Close toast on leave map page
  useIonViewWillLeave(() => {
    if (mailVerificationToast) {
      closeToast()
    }
  },[router])

  // Show toast on enter map page every route change
  useIonViewDidEnter(()=>{
    openToast()
  },[router])

  // Close toast on search modal open
  useEffect(() => {
    if (searchModal.status) {
      closeToast()
    }
  }, [searchModal]);

  return (
    <IonToast
      onClick={goToProfile}
      isOpen={mailVerificationToast}
      position="top"
      header={isMailEntered ? t('confirm-your-mail') : t('enter-your-mail')}
      message={isMailEntered ? t('check-your-mail'): t('go-to-profile')}
      icon={WarningIcon}
      swipeGesture={"vertical"}
      onDidDismiss={closeToast}
      className={"custom-toast"}
      style={{'--start': '16px', '--end': '16px'}}
      positionAnchor="StoryHeader"
      buttons={[
        {
          icon: CloseIcon,
          htmlAttributes: {
            'aria-label': 'close',
          },
          handler: closeToast
        }
      ]}
    />
  );
};

export default MailVerificationToast;