import {TGIcon} from "../TGElements";
import TGImg from "../TGElements/TGImg";
import {MasterCard, Visa} from "../../assets/images";
import {t} from "i18next";
import {useHistory} from "react-router";

const PaymentMethods = ({defaultCard}) => {
    const {push} = useHistory()

    return (
        <div className={"PaymentMethods"}>
            <p className={"ContainerTitle"}>{t("payment-method")}</p>
            {!defaultCard ?
                <div className={"PaymentMethodsContainer NoCard"}>
                    <p style={{fontWeight: 600, textTransform: "capitalize"}}>{t("add-card")}</p>
                    <div style={{paddingRight:"10px",marginLeft: "auto", marginTop: "3px"}} onClick={() => push('/menu/payments/add-payment-method', {menuModal: true})} >
                        <TGIcon
                            name={"circle-dark-plus"}
                            width={40}
                            height={40}/>
                    </div>
                    <div className={"PaymentMethodsContainerEnd"}></div>
                </div>
                :
                <div onClick={() => push('/menu/payments/registered-cards')}
                     className={"PaymentMethodsContainer SelectedCard"}>
                    <div className={"PaymentMethodsContainerStart"}>
                        <TGImg width={30} src={defaultCard?.card?.brand === "MasterCard" ? MasterCard : Visa}/>
                    </div>
                    <div className={"PaymentMethodsContainerMiddle"}>
                        <p>{defaultCard?.holder_name}</p>
                        <p>************{defaultCard?.card?.last4}</p>
                    </div>
                    <div style={{marginLeft: "auto", marginTop: "20px", paddingRight:"13px"}}>
                        <button className={"ChangePaymentMethodButton"}>{t("change")}</button>
                    </div>
                </div>
            }
        </div>
    )
};

export default PaymentMethods;
