import React from 'react';
import {TGInput, TGPage, TGText} from "../components";
import {useTranslation} from "react-i18next";
import Subscriptions from "../components/Membership/Subscriptions";
import {useDispatch, useSelector} from "react-redux";
import {plateValidation} from "../helpers";
import {Alert} from "../helpers/alert";
import {setMembershipPlate} from "../redux/slices/membershipSlice";
import ParkPaymentMethodModal from "../components/Membership/ParkPaymentMethodModal";
import {InvoiceTypeWrapper} from "../components/ChangePaymentMethodModal";
import {t} from "i18next";

const AddSubscription = () => {
    const {membershipPlate} = useSelector(state => state.membership)
    const {selectedCompanyId} = useSelector(state => state.session)
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const changeHandler = (e) => {
        const plate = e.target.value.toUpperCase().trim();
        dispatch(setMembershipPlate(plate))
    }

    return (
        <TGPage
            title={t('memberships')}
            scrollY={false}
            backButton={true}
            cssClass={"AddSubscription"}
        >
            <TGText margin={"20px 0 5px 0"} fontWeight={600} fontSize={18}>{t("add-plate")}</TGText>
            <TGInput
                type={"text"}
                value={membershipPlate}
                onInputChange={changeHandler}
                placeholder={t("34ABC123")}
                borderRadius={10}
            />

            <Subscriptions/>

            <ParkPaymentMethodModal/>

        </TGPage>
    );
};

export default AddSubscription;