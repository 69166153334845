import { TGIcon, TGText } from "../../TGElements";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Header = () => {
  const { activeSession } = useSelector((state) => state.session);
  const { vehicles } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const vehicleText = vehicles?.find((v) => v.is_default === 1);
  return (
    <div className="InformationModalHeader">
      <TGText fontWeight={"bold"} fontSize={22} width={156} wrapWithDots={true}>
        {vehicleText.model_text ?? vehicleText.plate }
      </TGText>
      <div className="ChargingLabel">
        <TGIcon name="charging-lightning-second" size={24} />
        <TGText color={"var(--tg-white)"} fontSize={14} fontWeight={"regular"}>
          {t("charging")}
        </TGText>
      </div>
    </div>
  );
};

export default Header;
