import React from 'react';
import {IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import {TGText} from "../../index";
import TGIcon from "../../TGElements/TGIcon";
import {useDispatch, useSelector} from "react-redux";
import TGImg from "../../TGElements/TGImg";
import {MasterCard, Visa} from "../../../assets/images";
import {api, getActiveSession, setDefaultCard} from "../../../helpers";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {setChangeCreditCardModal, setChangePaymentMethodModal} from "../../../redux/slices/modalSlice";

const PaymentTypeModal = () => {
  const {creditCardModalStatus:isActive} = useSelector(state => state.modal.changePaymentMethodModal)
  const {t} = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {activeSession} = useSelector(state => state.session)
  const {paymentMethods,balance} = useSelector(state => state.user)
  let defaultCard = null
  let maxTwoCards = []

  if (paymentMethods) {
    defaultCard = paymentMethods.find(card => card?.default === 1);
    maxTwoCards = paymentMethods.slice(0, 2)
  }

  const activeMethod = activeSession.payment_type_id === 2 ? "wallet" : "card";

  const changePaymentMethodHandler = async (cardId) => {
    const code = activeSession.connection
    const transactionId = activeSession.id
    if ((balance <= 0 && cardId === 'wallet') || (activeSession.payment_type_id === 1 && defaultCard.id === cardId)) return false

    if (cardId === 'wallet' && balance > 0) {
      const res = await api.changePaymentMethod(code, transactionId, 2)
      if (res) {
        const renewActiveSession = await getActiveSession()
        if (renewActiveSession) return dispatch(setChangeCreditCardModal(false))
      }
    }

    const defaultCardRes = await setDefaultCard(cardId)
    const res = await api.changePaymentMethod(code, transactionId, 1)
    if (res) {
      const renewActiveSession = await getActiveSession()
      if (renewActiveSession) dispatch(setChangeCreditCardModal(false))
    }
  }

  const clickAddPaymentMethod = () => {
    dispatch(setChangeCreditCardModal(false))
    dispatch(setChangePaymentMethodModal(false))
    history.push('/menu/payments/add-payment-method')
  }

  const goToWallet = () => {
    dispatch(setChangeCreditCardModal(false))
    dispatch(setChangePaymentMethodModal(false))
    history.push('/menu/wallet')
  }

  const closeModalHandler = () => {
    dispatch(setChangeCreditCardModal(false))
  }

  return (
    <IonModal
      onDidDismiss={closeModalHandler}
      isOpen={isActive}
      showBackdrop={true}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      style={{'--height': 'auto'}}
    >

      <IonHeader className="ion-no-border ion-padding-start ion-padding-end">
        <IonToolbar className="TGHeaderToolbar ion-padding-top ion-padding-bottom">
          <IonTitle className="ion-no-padding ion-text-capitalize">{t('select-payment-method')}</IonTitle>
          <IonButtons slot="end">
            <TGIcon name={"close-modal"} size={30} onClick={() => dispatch(setChangeCreditCardModal(false))}/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div className={'ion-padding-start ion-padding-end'}>

        <div
          className={`CardWrapper`}
          onClick={()=>changePaymentMethodHandler('wallet')}
        >
          <div className={"CardDetail"}>
            <TGIcon name={"small-wallet"} width={37} height={32} />
            <TGText fontSize={16} fontWeight={600}>{t('energy-wallet')}</TGText>
          </div>
          {balance > 0 &&
            <div style={{display: "flex", alignItems: "center", marginLeft: "auto"}}>
              <TGText fontSize={16} fontWeight={500} margin={"0 20px 0 0"} textTransform={"none"}>
                <b>{balance ?? '0'}</b> kw
              </TGText>
              {
                activeMethod === 'wallet' ? (
                  <TGIcon
                    name={"circle-green-tick"}
                    width={25}
                    height={25}
                    style={{marginLeft: "auto"}}
                  />
                ) : (
                  <div className={"empty-circle"}></div>
                )
              }
            </div>
          }

          {balance <= 0 && (<>
            <div className={`AddBalance ${balance === 0 && 'zero-balance'}`} onClick={goToWallet}>
              <TGText fontSize={12} fontWeight={600} color={"var(--tg-white)"}>
                {t('SelectPaymentMethod.add-balance')}
              </TGText>
            </div>

            <div className={"bottomInfo"}>
              <TGIcon name={"fill-info"} size={13}/>
              <TGText fontSize={11} fontWeight={500} color={"var(--tg-secondary-text)"} textTransform={"none"}>
                {balance === 0 ? t('zero-balance-text') : t('negative-balance-text')}
              </TGText>
            </div>
          </>)}

        </div>

        {maxTwoCards.map((card, index) => (
          <div
            key={index}
            className={`CardWrapper`}
            onClick={() => changePaymentMethodHandler(card.id)}
          >
            <div className={"CardDetail"}>
              <div className={"PaymentMethodsContainerStart"}>
                <TGImg width={41} src={card?.card.brand === "MasterCard" ? MasterCard : Visa}/>
              </div>
              <div className={"PaymentMethodsContainerMiddle"}>
                <TGText fontSize={16} fontWeight={500}>**** **** **** {card?.card?.last4}</TGText>
              </div>
            </div>
            {
              activeMethod === "card" && card.default === 1 ? (
                <TGIcon
                  name={"circle-green-tick"}
                  width={25}
                  height={25}
                  style={{marginLeft: "auto"}}
                />
              ) : (
                <div className={"empty-circle"}></div>
              )
            }
          </div>
        ))}

        <div className={`CardWrapper`} onClick={clickAddPaymentMethod}>
          <div className={"CardDetail"}>
            <TGText fontSize={16} fontWeight={600} margin={'0 10px 0 0'}>{t('add-card')}</TGText>
          </div>
          <TGIcon
            name={"circle-dark-plus"}
            width={40}
            height={40}
            style={{marginLeft: "auto"}}
          />
        </div>

      </div>

    </IonModal>
  );
};

export default PaymentTypeModal;