//React
import { useEffect, useState } from "react";
//Components
import SocketTypeItem from "./SocketTypeItem";

const SocketTypesList = ({ socketTypes, isExpand }) => {
  
  const [list, setList] = useState([]);

  useEffect(() => {
    if(!isExpand){
      setList(socketTypes.slice(0, 6))
    }else{
      setList(socketTypes)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpand])

  return (
    <div className="SocketTypesList">
      {list.map((item) => (
        <SocketTypeItem key={item.id} item={item} />
      ))}
    </div>
  );
};

export default SocketTypesList;
