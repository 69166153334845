import {useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'
import {TGIcon, TGPage} from "../components";
import PaymentMethods from "../components/Wallet/paymentMethods";
import Packages from "../components/Wallet/packages";
import {useEffect, useState} from "react";
import {api} from "../helpers";
import {Alert} from "../helpers/alert";
import {useSelector} from "react-redux";
import {useDialog} from "../hooks";

const AddBalance = () => {
    const showDialog = useDialog()
    const {push} = useHistory()
    const {t} = useTranslation()
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [amount, setAmount] = useState(null)
    const [defaultCard, setDefaultCard] = useState(null);
    const {paymentMethods, balance} = useSelector(state => state.user)


    const clickHandler = () => {
        showDialog(t("wallet-payment-approve"), "yes", ()=>buyBalance(), "no")
    }


    const buyBalance = () => {
        if (!defaultCard){
            Alert.error(t("no-registered-cards-description"))
            return
        }



        let res;
        if (selectedPackage) {
            res = api.buyBalanceWithPackageId(selectedPackage.id);
        }

        res.then((r) => {
            if (r?.data?.success){
                push('/menu/wallet/balance-added', {amount:amount})
            }
        })


    }


    const getDefaultCard =  async () => {
        if(paymentMethods){
            paymentMethods.forEach((val) => {
                val.default && setDefaultCard(val)
            })
        }
    };

    useEffect(() => {
        getDefaultCard()
    },[paymentMethods])



    return (
        <TGPage
            title={t("add-balance")}
            scrollY={false}
            backButton={true}
        >
            <div className={"Wallet"}>
                <Packages amount={amount} setAmount={setAmount}
                          selectedPackage={selectedPackage}
                          setSelectedPackage={setSelectedPackage}/>

                <PaymentMethods defaultCard={defaultCard}/>
                {amount && (
                    <div className={`AddBalanceConfirmContainer`}>
                        <div style={{paddingLeft: "10px"}}>
                            <p className={"AmountToBePaidLabel"}>{t("amount-to-be-paid")}</p>
                            <p className={"AmountToBePaid"}>{selectedPackage.discountedPrice ?? selectedPackage.originalPrice}₺</p>
                        </div>
                        <button onClick={clickHandler} className={`AddBalanceConfirmButton ${!defaultCard && "disabled"}`}>
                        <TGIcon style={{marginRight: "7px"}} name={"wallet-with-plus"}/> {t("pay-balance")}</button>
                    </div>
                )}
            </div>
        </TGPage>
    )
}

export default AddBalance