import {store} from "../redux/store";
import {resetSlice, setUser} from "../redux/slices/userSlice";
import {setMenuModal} from "../redux/slices/modalSlice";
import {getStationDetail, stopCharge} from "./apiFunctions";
import md5 from "blueimp-md5";
import {StatusBar, Style} from "@capacitor/status-bar";
import {Capacitor} from "@capacitor/core";
import {BarcodeScanner} from "@capacitor-community/barcode-scanner";
import {Alert} from "./alert";
import {t} from 'i18next'
import {setFlashlight} from "../redux/slices/deviceSlice";
import history from "./history";
import {setViewed, sortStories, sortStoryPreviews} from "../redux/slices/storiesSlice";
import {Directory, Filesystem} from "@capacitor/filesystem";

export const checkLocalStorage = (type) => {
    if (type === 'user') {
        let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};
        if (user.id) {
            store.dispatch(setUser(user))
        }
    }
}
export const logout = async () => {
    store.dispatch(setMenuModal(false))
    store.dispatch(resetSlice())
    localStorage.clear()
    return true;
}
export const stopSession = async () => {
    return await stopCharge();
}

//Create Hash token with timestamp__formatted by UTC timezone and HashKey_from env file
export const createHash = () => {
    const HASH_KEY = process.env.REACT_APP_HASH_KEY
    let dateStamp = Date.now() / 1000 | 0
    let str = `${HASH_KEY}-${dateStamp}`
    return md5(str);
}

export const handleStatusbar = async (isDark) => {
    if (isDark) {
        await StatusBar.setStyle({style: Style.Dark})
        return;
    }
    await StatusBar.setStyle({style: Style.Light})
}

export const startScan = async () => {
    // Check if the platform is native
    if (!Capacitor.isNativePlatform()) {
        return {scanner: false};
    }

    // Check camera permission
    const permission = await BarcodeScanner.checkPermission({force: true});
    if (!permission.granted) {
        Alert.error(t("grant-camera-permission"));
        return {scanner: false};
    }

    return {scanner: true};
};

const stopScan = async () => {
    const {isEnabled} = await BarcodeScanner.getTorchState();
    if (isEnabled) {
        BarcodeScanner.disableTorch();
    }
    store.dispatch(setFlashlight(false));
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    document.body.classList.remove("qrscanner");
};

export const parseParamsString = (stringParams) => {
    const params = {}
    stringParams.forEach((value, key) => params[key] = value);
    return params
}

export const getPlatformByUserAgent = () => {
    if (/iphone/i.test(navigator.userAgent)) {
        return "iOS";
    } else if (/ipad/i.test(navigator.userAgent)) {
        return "iOS";
    } else if (/android/i.test(navigator.userAgent)) {
        return "android";
    }
}

export const formatDistance = (distance) => {
    if (distance > 1) {
        return Math.round(distance) + ' km';
    } else {
        return Math.round(distance * 1000) + ' m';
    }
}

//TODO::TEMP SOLUTION. UNTIL DETERMINE HOW PRICING LOOK LIKE
export const formatStationPrice = (connections) => {
    let acPrice = null;
    let dcPrice = null;

    connections.forEach(function (connection) {
        if (acPrice && dcPrice) {
            return;
        }

        if (connection.connection_type_name === "AC" && !acPrice) {
            acPrice = connection.price_per_kwh;
        }

        if (connection.connection_type_name === "DC" && !dcPrice) {
            dcPrice = connection.price_per_kwh;
        }
    })

    if (acPrice && dcPrice) {
        return `AC: ${acPrice}₺ / DC: ${dcPrice}₺`;
    } else if (dcPrice) {
        return `${dcPrice}₺`;
    } else {
        return `${acPrice}₺`;
    }
}


export const isUserLoggedIn = () => {
    return store.getState().user.user.token.length > 0;
}

export const env = (value, prefix = "REACT_APP_") => {
    let v = prefix + value;
    return process.env[v];
};

export const plateValidation = (plate) => {
    const plateRegex = "^(0[1-9]|[1-7][0-9]|8[01])((\\s?[a-zA-Z]\\s?)(\\d{4,5})|(\\s?[a-zA-Z]{2}\\s?)(\\d{3,4})|(\\s?[a-zA-Z]{3}\\s?)(\\d{2,3}))$"
    const tempPlate = plate.replace(/\s+/g, '').toUpperCase();
    return tempPlate.match(plateRegex) !== null;
}

export const redirectUrl = (url) => {
    try {
        const urlValidation = new URL(url)
        if (urlValidation) {
            return window.open(url, '_blank')
        }
    }
    catch (e) {
        if (url.startsWith('/station')) {
            const stationId = url.split('/')[2];
            if (!stationId) return;
            getStationDetail({id:stationId}, true)
            return
        }
        return history.push(url)
    }
}

export const handleStoryViewed = (id) => {
    const dispatch = store.dispatch
    const {viewed,isShowStories} = store.getState().stories
    dispatch(sortStories())
    dispatch(sortStoryPreviews())
    if (viewed.includes(id)) return
    dispatch(setViewed([...viewed, id]))
}

export const cacheClear = async (version) => {
    if (Capacitor.getPlatform() !== 'ios') return
    const {live_server} = store.getState().config
    const deleteFiles = (files) => {
        files.forEach(file => {
            if (file.type === 'file') Filesystem.deleteFile({path: file.uri})
        });
    };

    const readFiles = await Filesystem.readdir({
        path: 'com.toger.main',
        directory: Directory.Cache
    })

    if (readFiles.files.length > 0) {
        if (version > live_server.version) {
            deleteFiles(readFiles.files)
        }
    }
}