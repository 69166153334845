//React
import {useState, useEffect, useRef} from 'react'
import { useHistory } from 'react-router';
//Components
import { EnterSMSCountdown } from '../components/Register';
import { CustomLogo, TGButton, TGIcon, TGInput, TGPage, TGText } from '../components';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/slices/userSlice';
//Helpers
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever';
import { api, getUser, registerPhone } from '../helpers';
import { useTranslation } from 'react-i18next';
import { Alert } from '../helpers/alert';
import { Capacitor } from '@capacitor/core';

const ConfirmPhone = () => {
    const {t} = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const inputRef = useRef()
    const [count, setCount] = useState(180)
    const [code, setCode] = useState("")
    const [isCountEnd, setCountEnd] = useState(false)
    const [locationProps, setLocationProps] = useState({})
    const [isConfirmDone, setConfirmDone] = useState(false)
    const {keyboardHeight} = useSelector(state => state.device)
    useEffect(() => {
        if(history.location.state){
            setLocationProps(history.location.state)
        }
    }, [history.location.state]);

    useEffect(() => {
        if(isConfirmDone) {
            getUser().then((res) => {
                if(res.status === 200 && res.data.data[0].email){
                    history.push('/map')
                }else{
                    history.push('/menu/edit-user')
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
    }, [isConfirmDone])

    useEffect(() => {
        setTimeout(() => {
          inputRef?.current?.setFocus(true)
        }, 200)
        watchConfirmSms()
      }, [])

    const handleSubmit = async e => {
        e && e.preventDefault();

        const phone = locationProps.phone.replace(/[^\d+]/g, '')
        const res = await api.activatePhone({ code, phone })

        if(!res || res?.status !== 200){
            Alert.error(t("try-again"))
            return;
        }

        if(res.data.success === false){
            Alert.error(res?.data?.message)
            return;
        }
        setUserAccount(res?.data)
    }

    const setUserAccount = (user) => {
        let stringUserObj = JSON.stringify(user)
        localStorage.setItem("user", stringUserObj)
        dispatch(setUser(user))
        setConfirmDone(true)
    }

    const sendAgain = async () => {
        const result = await registerPhone(locationProps.phone)
        if(result.success){
            setCount(180)
        }
    }

    const watchConfirmSms = () => {
        if(Capacitor.getPlatform() === "android"){
            SmsRetriever.startWatching()
                .then(res=> {
                    const result = res.Message.match(/\b\d{4}\b/)
                    setCode(result[0])
                })
                .catch(error => console.error(error)); 
        }
    }

    useEffect(() => {
        if(code.length === 4) {
            handleSubmit()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code])

    return (
      <TGPage scrollY={false} backButton={true}>
        <form className="confirm-phone-form" onSubmit={handleSubmit}>
            <CustomLogo type='mono' width={202} style={{
                margin: `${keyboardHeight===0 ? "110px" : "20px"} auto`,
                transition:'all .3s ease'
            }}/>
          <TGText fontWeight={600} fontSize={16} margin="0 0 16px 0" color={"var(--tg-secondary-text)"}>
          {t("code-sended-to-your-phone")}
          </TGText>
          <div style={{display: "flex", transition:'all .3s ease' ,alignItems: "center", margin: keyboardHeight === 0 ? "0 0 46px 0" : "0 0 20px 0"}}>
            <TGText fontWeight={"semibold"} fontSize={keyboardHeight === 0 ? 22 : 16} margin="0 11px 0 0">
            {locationProps.phone}
            </TGText>
            <div
            style={{display: "flex", alignItems: "center", flexDirection: "row"}}
            onClick={() => history.push("/register-phone", {phone: locationProps.phone})}
            >
                <TGIcon
                name={"edit"}
                fill={"#166DF7"}
                />
                <TGText fontWeight={"medium"} fontSize={14} color={"var(--tg-link)"} >
                {t("edit")}
                </TGText>
            </div>
          </div>
          <TGInput
            label={t("enter-code")}
            type={"number"}
            margin="0 0 10px 0"
            autofocus={true}
            textAlign="center"
            onInputChange={(e) => setCode(e.detail.value)}
            inputmode={"numeric"}
            reference={inputRef}
          />
          {count < 100 &&  <TGText
            fontSize={14}
            fontWeight="medium"
            textAlign={"right"}
            margin="0 0 40px 0"
            color={"var(--tg-secondary-text)"}
            width={"100%"}
            onClick={() => sendAgain()}
            >
                {t("send-again")}
            </TGText>}
            {code &&
                <TGButton type="submit" disabled={isCountEnd} margin="10px 0">
                    {t("next")}
                </TGButton>
            }
            <EnterSMSCountdown setCountEnd={setCountEnd} count={count} setCount={setCount}/>
        </form>
      </TGPage>
    );
}

export default ConfirmPhone;
